import React from "react"
import Helmet from "react-helmet"
import Layout from "../components/layout"

import plate from "../assets/images/plate.png"

const LeftSidebar = props => (
  <Layout>
    <Helmet>
      <title>Products | Live Cell Technologies Canada</title>
      <meta name="description" content="Products" />
    </Helmet>

    <div id="main" className="wrapper style1">
      <div className="container">
        <header className="major">
          <h2>Products</h2>
          <p>
            We carry a wide variety of products to suit your needs.{" "}
            <a href="/#contact">Ask us</a> about custom specifications!
          </p>
        </header>

        <section id="content">
          <p>
            Our platform is compatible with single cell, confluent monolayers,
            and everything in between. Every order is custom prepared on demand,
            with customizations for well format, modulus, bead fluorescence,
            glass thickness. We can offer any combination, make what you want,
            and are happy to innovate solutions for your challenges. Not sure
            what might work? We’re happy to discuss with you.
          </p>
          <div className="row">
            <div className="col-2 col-12-xsmall">
              <h4>Plate configuration</h4>
              <ul className="alt">
                <li>Multiwell 96</li>
                <li>Multiwell 48</li>
                <li>Multiwell 24</li>
                <li>Multiwell 12</li>
                <li>Multiwell 6</li>
                <li>Single well dish</li>
              </ul>
            </div>
            <div className="col-3 col-12-xsmall">
              <h4>Stiffness</h4>
              <div className="table-wrapper">
                <table>
                  <thead>
                    <tr>
                      <th>Standard</th>
                      <th>Tissue Analog</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1 kPa</td>
                      <td>Brain</td>
                    </tr>
                    <tr>
                      <td>4 kPa</td>
                      <td>Breast epithelium</td>
                    </tr>
                    <tr>
                      <td>12 kPa</td>
                      <td>Breast tumour</td>
                    </tr>
                    <tr>
                      <td>12 kPa</td>
                      <td>Airway smooth muscle</td>
                    </tr>
                    <tr>
                      <td>20 kPa</td>
                      <td>Heart</td>
                    </tr>
                    <tr>
                      <td>80 kPa</td>
                      <td>Heart fibrosis</td>
                    </tr>
                    <tr>
                      <td>100 kPa</td>
                      <td>*Custom</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-2 col-12-xsmall">
              <h4>Surface Treatment</h4>
              <ul className="alt">
                <li>None</li>
                <li>Poly-(L)-Lysine</li>
                <li>Special Ligand</li>
              </ul>
            </div>
            <div className="col-2 col-12-xsmall">
              <h4>Custom Ligation Kits</h4>
              <ul className="alt">
                <li>Collagen</li>
                <li>Fibronectin</li>
                <li>Laminin</li>
              </ul>
            </div>
            <div className="col-2 col-12-xsmall">
              <h4>Fiduciary Bead Dyes</h4>
              <ul className="alt">
                <li>DAPI (359 nm / 457 nm)</li>
                <li>FITC (495 nm / 519 nm)</li>
                <li>TRITC (544 nm / 570 nm)</li>
                <li>DiD (644 nm / 665 nm)</li>
              </ul>
            </div>
          </div>

          <a className="image fit">
            <img src={plate} alt="CFS Plate" />
          </a>
        </section>
      </div>
    </div>
  </Layout>
)

export default LeftSidebar
